/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react"
import { Layout, theme } from "antd"
import Header from "./components/Header"
import SideMenuBar from "./components/SideMenuBar"
import BreadcrumbRoute from "./components/BreadcrumbRoute"
import secureLocalStorage from "react-secure-storage"
import {
  generateRedirectPath,
  getNavigationComponent,
  getSelectedSubMenu,
} from "../utils/functions"
import NavigationHOC from "../HOCs/Navigation"
import { Outlet } from "react-router-dom"
const { Content } = Layout

export default NavigationHOC(({ redirect, path }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const [activeMenu, setActiveMenu] = useState("")
  const [activeSubMenu, setActiveSubMenu] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const localData = secureLocalStorage.getItem("authUser")

  const navbarMenuChangeHandler = (key, type) => {
    type === "menu" ? setActiveMenu(key) : setActiveSubMenu(key)
  }

  useEffect(() => {
    const subMenu = getSelectedSubMenu(activeMenu)
    setActiveSubMenu(subMenu)
    let redirectPath = generateRedirectPath(activeMenu, subMenu)
    redirect(redirectPath)
  }, [activeMenu])

  useEffect(() => {
    activeSubMenu && redirect(`${activeMenu}/${activeSubMenu}`)
  }, [activeSubMenu])

  useEffect(() => {
    if (localData) {
      redirect("/vendorList")
    }
    const navigation = getNavigationComponent(path)
    setActiveMenu(navigation[0])
  }, [])

  return (
    <Layout>
      <Header
        selectedMenu={activeMenu}
        menuSelectHandler={navbarMenuChangeHandler}
      />
      <Layout>
        {activeSubMenu && (
          <SideMenuBar
            collapsed={collapsed}
            selectedMenu={activeMenu}
            setCollapsed={setCollapsed}
            activeMenu={activeMenu}
            activeSubMenu={activeSubMenu}
            menuSelectHandler={navbarMenuChangeHandler}
          />
        )}
        <Layout
          style={{
            marginLeft: !activeSubMenu && "0px",
          }}
          className={collapsed ? "collapsed" : "main-content"}
        >
          <BreadcrumbRoute
            activeMenu={activeMenu}
            activeSubMenu={activeSubMenu}
          />

          <Content
            style={{
              padding: 24,
              margin: "0 24px",
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
})
