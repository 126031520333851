import React, { useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import CONFIG from "../utils/constants"
import Layout from "../layout"
import PageNotFound from "../pages/pageNotFound"
import secureLocalStorage from "react-secure-storage"
import AuthContainer from "../../src/layout/components/AuthContainer"
import NavigationHOC from "../HOCs/Navigation"
import PrivacyPolicy from "../pages/privacyPolicy"

export default NavigationHOC(({ redirect }) => {
  const { ROUTES } = CONFIG

  const localData = secureLocalStorage.getItem("authUser")

  const path = window.location.pathname
  useEffect(() => {
    if (localData && path === "/") {
      redirect("/vendorList")
    }
  }, [localData, path, redirect])

  return (
    <Routes>
      <Route
        path={ROUTES.HOME.path}
        element={ROUTES.HOME.component}
        key={ROUTES.HOME.path}
      />
      <Route
        element={
          <AuthContainer>
            <Layout />
          </AuthContainer>
        }
      >
        {Object.values(ROUTES)
          .slice(1)
          .map((route) => {
            return (
              <Route
                path={route.path}
                element={route.component}
                key={route.path}
              />
            )
          })}
      </Route>
      <Route path="/*" element={<PageNotFound />} key="/*" />
      <Route path="/policy" element={<PrivacyPolicy />} key="/policy" />
    </Routes>
  )
})
