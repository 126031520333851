import React from "react"

const PrivacyPolicy = () => {
  return (
    <div className="policy">
      Privacy Policy
      <br />
      <br />
      Last updated: 04-07-2023
      <br />
      <br />
      At Innow8 Apps, we are committed to protecting your privacy and ensuring
      the security of your personal information. This Privacy Policy outlines
      how we collect, use, and safeguard the information you provide when using
      our services.
      <br />
      <br />
      Information Collection and Usage
      <br />
      <br />
      1.1. Google Login
      <br />
      <br />
      When you choose to log in to our platform using your Google account, we
      will request your permission to access certain information associated with
      your account. This includes your name, email address, profile picture, and
      basic account information. We use this information to authenticate your
      identity and provide a personalized experience.
      <br />
      <br />
      1.2. Gmail Access
      <br />
      <br />
      To enhance your user experience and provide specific features, we may
      request access to read and write your Gmail messages. This permission
      allows us to:
      <br />
      <br />
      Read your Gmail messages: We will access your inbox and email content
      solely for the purpose of enabling features such as email organization,
      search, and notifications. We do not store or share your email content
      with any third parties.
      <br />
      <br />
      Write to your Gmail: We may send emails on your behalf as part of the
      functionality offered by our service. This includes sending notifications,
      updates, or reminders related to your account or interactions with our
      platform. We do not send promotional or marketing emails without your
      explicit consent.
      <br />
      <br />
      Data Security
      <br />
      <br />
      Protecting your information is our top priority. We implement
      industry-standard security measures to safeguard your personal data from
      unauthorized access, disclosure, alteration, or destruction. We regularly
      review and update our security practices to maintain the highest level of
      protection.
      <br />
      <br />
      Data Retention
      <br />
      <br />
      We retain your personal information only for as long as necessary to
      fulfill the purposes outlined in this Privacy Policy or as required by
      law. Once the information is no longer needed, we will securely delete or
      anonymize it.
      <br />
      <br />
      Third-Party Integration
      <br />
      <br />
      We may use trusted third-party service providers to assist us in
      delivering our services. These providers may have access to your personal
      information to perform specific tasks on our behalf, such as data storage,
      maintenance, or customer support. We ensure that any third-party partners
      adhere to strict privacy and security standards.
      <br />
      <br />
      Your Rights
      <br />
      <br />
      You have the right to review, update, and delete the personal information
      we hold about you. If you wish to exercise any of these rights, please
      contact us using the information provided at the end of this policy.
      <br />
      <br />
      Changes to this Policy We reserve the right to modify or update this
      Privacy Policy at any time. We will notify you of any significant changes
      by posting the revised policy on our website or through other
      communication channels. It is your responsibility to review this policy
      periodically for any updates.
      <br />
      <br />
      Contact Us
      <br />
      <br />
      If you have any questions, concerns, or requests regarding this Privacy
      Policy or the way we handle your personal information, please contact us
      at <a href="mailto:contact@innow8apps.com">contact@innow8apps.com</a>
      <br />
      <br />
      Remember to review and tailor the privacy policy to align with your
      specific platform, services, and legal requirements. It's also recommended
      to consult with legal professionals to ensure compliance with applicable
      laws and regulations.
    </div>
  )
}

export default PrivacyPolicy
