import secureLocalStorage from "react-secure-storage"
import NavigationHOC from "../../HOCs/Navigation"

export default NavigationHOC(({ children, redirect }) => {
  const localData = secureLocalStorage.getItem("authUser")
  if (!localData) {
    redirect("/")
  }

  return children
})
