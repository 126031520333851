import CONFIG from "../constants";

const { NAVBAR_MENU } = CONFIG;

export const getNavigationComponent = (pathName) => {
  let tempArr = [...new Set(pathName.split("/"))];
  tempArr.shift();
  return tempArr;
};

export const getSelectedSubMenu = (activeMenu) => {
  let { subMenu } = NAVBAR_MENU.find(({ key }) => key === activeMenu) || {
    subMenu: [],
  };
  return subMenu.length ? subMenu[0].key : null;
};

export const generateRedirectPath = (activeMenu, activeSubMenu) => {
  return activeSubMenu ? `${activeMenu}/${activeSubMenu}` : `${activeMenu}`;
};

 
