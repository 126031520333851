/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import OAuth from "./OAuth";
import LoginImg from "../../assets/images/login.png";
import LoginTitle from "../../assets/images/login-title.png";
import Loginlogo from "../../assets/images/POC.png";

export default () => (
  <div className="login">
    <div className="container">
      <div className="login-logo">
        <img src={Loginlogo} alt="Loginlogo" />
      </div>
      <div className="login-inner row">
        <div className="login-data col-12 col-md-5">
          <img
            src={LoginTitle}
            alt="ai powered invoice"
            className="loginTitle"
          />
          <p className="subtitle">
            Simplify invoice management system with AI Automation. Streamline
            processes, eliminate manual tasks. Enhance efficiency.
          </p>
          <OAuth />
          <p className="login-info">
            <span>*</span>By login into the software, you consent to granting
            access to your email account to AIS for seamless integration.
          </p>
          <p>
            <span>*</span>
            <a href="/policy">privacy policy</a>
          </p>
        </div>
        <div className="login-image col-12 col-md-7">
          <img src={LoginImg} alt="invoice pay" />
        </div>
      </div>
    </div>
  </div>
);
