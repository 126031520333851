import { useCallback, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import AddUpdateVendorData from "./AddUpdateVendorData";
import { deleteVendor, getAllVendorsData } from "../../api/function";
import CustomTable from "../../utils/common/CustomTable";
import CustomButton from "../../utils/common/CustomButton";

const ActiveVendors = () => {
  const columns = [
    // {
    //   key: "_id",
    //   title: "ID",
    //   dataIndex: "_id",
    //   align: "center",
    //   className: "table_ID",
    // },
    {
      key: "department",
      title: "Department",
      dataIndex: "department",
      align: "center",
    },
    {
      key: "managerName",
      title: "Manager Name",
      dataIndex: "managerName",
      align: "center",
      className: "table_managerName",
    },
    {
      key: "managerEmail",
      title: "Manager Email",
      dataIndex: "managerEmail",
      align: "center",
    },
    {
      key: "vendorName",
      title: "Vendor Name",
      dataIndex: "vendorName",
      align: "center",
      className: "table_vendorName",
    },
    {
      key: "Vendor Email",
      title: "Vendor Email",
      dataIndex: "vendorEmail",
      align: "center",
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => (
        <div className="action-wraper">
          <button
            className="edit-icon action-btn"
            onClick={() => editData(record)}
            disabled={
              (deleteSuccess?.length && deleteSuccess?.includes(record?._id)) ||
              disabledAction == true
            }
          >
            <FiEdit className="icon" />
          </button>
          <button
            className={
              disabledAction ||
              (deleteSuccess?.length && deleteSuccess?.includes(record?._id))
                ? "disable-delete-icon action-btn"
                : "delete-icon action-btn"
            }
            onClick={() => {
              deleteVendorObj(record?._id);
              setDeleteSuccess(record?._id);
            }}
            disabled={
              (deleteSuccess?.length && deleteSuccess?.includes(record?._id)) ||
              disabledAction == true
            }
          >
            <MdOutlineDelete className="icon" />
          </button>
        </div>
      ),
    },
  ];
  //view modal
  const [dataArr, setDataArr] = useState([]);
  const [modaldata, setModalData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [refetchData, setRefetch] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState([]);
  const [disabledAction, setDisabledAction] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const refetchVendorData = useCallback((value) => {
    setRefetch(value);
  }, []);

  //edit data
  const editData = useCallback((data) => {
    setModalData(data);
    setShowEditModal(true);
  }, []);

  const addVendor = useCallback(() => {
    setVendor(true);
  }, []);

  const deleteVendorObj = useCallback(async (vendorObjId) => {
    setDeleteSuccess(true);
    try {
      const res = await deleteVendor(vendorObjId);

      if (res) {
        setRefetch(true);
        toast.success("Vendor deleted successfully");
      }
    } catch (err) {
      // Handle the error appropriately
      toast.error(err?.response?.data?.error);
    }
  }, []);

  const fetchData = useCallback(async () => {
    // setFetchLoading(true)
    try {
      const res = await getAllVendorsData();

      if (res.data) {
        setFetchLoading(false);
        setDataArr(res?.data?.entry?.objects ? res?.data?.entry?.objects : []);
        setRefetch(false);
        setDeleteSuccess(false);
      }
    } catch (err) {
      // Handle the error
      setFetchLoading(false);
      setRefetch(false);
      toast.error(err?.response?.data?.error);
    }
  }, []);

  const options = {
    onOpen: () => {
      setDisabledAction(true);
    },
    onClose: () => setDisabledAction(false),
    autoClose: 500,
  };
  const formatString = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const renderData = dataArr.map(
    ({
      department,
      managerName,
      vendorName,
      managerEmail,
      vendorEmail,
      _id,
    }) => {
      return {
        department,
        managerEmail,
        vendorEmail,
        _id,
        managerName: formatString(managerName),
        vendorName: formatString(vendorName),
      };
    }
  );

  useEffect(() => {
    let isMounted = true;

    // Call the fetchData function once the component is mounted
    if (isMounted) {
      fetchData();
    }

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [refetchData, fetchData]);

  return (
    <>
      {showEditModal && (
        <AddUpdateVendorData
          Data={modaldata}
          setShowEditModal={setShowEditModal}
          setRefetch={refetchVendorData}
          options={options}
          setFetchLoading={setFetchLoading}
          fetchLoading={fetchLoading}
        />
      )}
      {vendor && (
        <AddUpdateVendorData
          setVendor={setVendor}
          setRefetch={refetchVendorData}
          options={options}
          setFetchLoading={setFetchLoading}
          fetchLoading={fetchLoading}
        />
      )}
      <div>
        <CustomButton
          className={fetchLoading ? "addDisabled" : "add-vendor"}
          onClick={addVendor}
          title={"Add New Vendor"}
          type={"button"}
          disabled={fetchLoading}
        />
      </div>
      <CustomTable
        columns={columns}
        dataSource={renderData}
        bordered
        fetchLoading={fetchLoading}
        dataArr={dataArr}
        refetchData={refetchData}
        rowSelection={false}
      />
    </>
  );
};
export default ActiveVendors;
