import React from "react";
import { RxCross2 } from "react-icons/rx";

const Modal = ({ children, onClose, title }) => {
  return (
    <div className="backdrop">
      <div className="view-modal edit-modal">
        <div className="top">
          <h4 className="mb-0">{title}</h4>
          <span className="close-modal">
            <RxCross2 onClick={onClose} />
          </span>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
