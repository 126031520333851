/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import {
  FileDoneOutlined,
  FileSyncOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import Dashboard from "../../pages/dashboard";
import ActiveVendors from "../../pages/vendors/ActiveVendors";
import InActiveVendors from "../../pages/vendors/InActiveVendors";
import PendingInvoices from "../../pages/invoices/PendingInvoices";
import CompletedInvoices from "../../pages/invoices/CompletedInvoices";
import Home from "../../pages/home";

const REDIRECT_URI = "https://ais.innow8.com";

export default {
  NAVBAR_MENU: [
    {
      key: "vendorList",
      label: "Vendor List",
      subMenu: [],
    },
    {
      key: "invoice",
      label: "Invoice",
      subMenu: [],
    },
  ],
  ROUTES: {
    HOME: {
      path: "/",
      component: <Home />,
    },

    DASHBOARD: {
      key: "vendorList",
      path: "/vendorList",

      component: <Dashboard />,
    },
    ACTIVE_VENDOR: {
      path: "/vendorList/activeVendor",
      component: <ActiveVendors />,
    },
    PENDING_INVOICE: {
      path: "/invoice",
      component: <PendingInvoices />,
    },
  },
  MOCK_DATA: [
    {
      id: 1,
      title: "Active",
      image:
        "https://cdn.osxdaily.com/wp-content/uploads/2014/11/mac-messages-icon.jpg",
      category: "",
      value: `$40000`,
    },
    {
      id: 2,
      title: "Inactive",
      image:
        "https://cdn.osxdaily.com/wp-content/uploads/2014/11/mac-messages-icon.jpg",
      category: "",
      value: `$14000`,
    },
    {
      id: 3,
      title: "complete",
      image:
        "https://cdn.osxdaily.com/wp-content/uploads/2014/11/mac-messages-icon.jpg",
      category: "",
      value: `50%`,
    },
    {
      id: 4,
      title: "pending",
      image:
        "https://cdn.osxdaily.com/wp-content/uploads/2014/11/mac-messages-icon.jpg",
      category: "",
      value: `18`,
    },
  ],
  BAR_GRAPH_DATA: [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ],
  CHART_DATA: [
    { name: "January", Total: 1200 },
    { name: "February", Total: 2100 },
    { name: "March", Total: 800 },
    { name: "April", Total: 1600 },
    { name: "May", Total: 900 },
    { name: "June", Total: 1700 },
  ],
  LINE_GRAPH_DATA: [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ],
  PIE_CHART_DATA_1: [
    {
      name: "Group A",
      value: 400,
    },
    {
      name: "Group B",
      value: 300,
    },
    {
      name: "Group C",
      value: 300,
    },
    {
      name: "Group D",
      value: 200,
    },
    {
      name: "Group E",
      value: 278,
    },
    {
      name: "Group F",
      value: 189,
    },
  ],
  PIE_CHART_DATA_2: [
    {
      name: "Group A",
      value: 2400,
    },
    {
      name: "Group B",
      value: 4567,
    },
    {
      name: "Group C",
      value: 1398,
    },
    {
      name: "Group D",
      value: 9800,
    },
    {
      name: "Group E",
      value: 3908,
    },
    {
      name: "Group F",
      value: 4800,
    },
  ],
  API_URL: {
    BASE_URL: "https://invoice-ai.onrender.com",
    getLoginURL: (URL) => `${URL}/login`,
  },
  BREAD_CRUMBS: {
    dashboard: "Vendor List",
    vendorList: "Vendor List",
    activeVendor: "Active Vendor",
    inactiveVendor: "In-Active Vendor",
    invoice: "Invoice",
    pendingInvoices: "Pending Invoices",
    completedInvoices: "Completed Invoices",
  },
  GOOGLE_AUTH_SETTINGS: {
    flow: "auth-code",
    redirect_uri: REDIRECT_URI,
    prompt: "consent",
    access_type: "offline",
  },
  GOOGLE_SCOPE:
    "openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  GMAIL_SCOPE:
    "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
};
