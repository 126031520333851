import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Modal from "../../utils/common/CustomModal"
import CustomButton from "../../utils/common/CustomButton"
import FormField from "../../utils/common/FormField"
import { createVendor, updateVendorEntry } from "../../api/function"

const initialValues = {
  department: "",
  managerName: "",
  managerEmail: "",
  vendorName: "",
  vendorEmail: "",
}

const AddUpdateVendorData = ({
  Data,
  setShowEditModal,
  setVendor,
  setRefetch,
  options,
  setFetchLoading,
  fetchLoading,
}) => {
  const [formData, setFormData] = useState(initialValues)
  const [validationErrors, setValidationErrors] = useState(initialValues)

  useEffect(() => {
    if (Data) {
      setFormData(Data)
    }
  }, [Data])

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))

    // Validate the field being changed
    const errors = validateField(name, value)
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name],
    }))
  }

  const validateField = (fieldName, value) => {
    const errors = {}
    let label =
      fieldName?.charAt(0).toUpperCase() +
      fieldName
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(" ")

    if (!value) {
      errors[fieldName] = `${label} is required`
    } else if (fieldName === "managerEmail" || fieldName === "vendorEmail") {
      if (!isValidEmail(value)) {
        errors[fieldName] = "Invalid email format"
      } else if (value === formData.vendorEmail) {
        errors[fieldName] = `${label} must be different from the vendor email.`
      } else if (value === formData.managerEmail) {
        errors[fieldName] = `${label} must be different from the manager email.`
      }
    }
    return errors
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const addVendor = async (data) => {
    try {
      setFetchLoading(true)
      const res = await createVendor(data)
      if (res) {
        toast.success("Vendor added successfully", options)
        setFetchLoading(false)
        setRefetch(true)
        setVendor(false)
      }
    } catch (err) {
      // Handle the error
      toast.error(err?.response?.data?.error)
    }
  }

  const updateVendorDetail = async (data, vendorId) => {
    try {
      setFetchLoading(true)
      const res = await updateVendorEntry(vendorId, data)
      if (res) {
        toast.success("Details updated successfully", options)
        setFetchLoading(false)
        setRefetch(true)
        setShowEditModal(false)
      }
    } catch (err) {
      // Handle the error
      toast.error(err?.response?.data?.error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Validate all fields before submission
    const errors = {}
    Object.keys(formData).forEach((fieldName) => {
      const fieldErrors = validateField(fieldName, formData[fieldName])
      if (Object.keys(fieldErrors).length > 0) {
        errors[fieldName] = fieldErrors[fieldName]
      }
      // If there are validation errors, update the state and halt submission
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors)
        return
      }
    })
    let data = {
      vendorEmail: formData.vendorEmail,
      managerEmail: formData.managerEmail,
      managerName: formData.managerName,
      department: formData.department,
      vendorName: formData.vendorName,
    }
    if (Data) {
      updateVendorDetail(data, formData._id)
      setShowEditModal(false)
    } else {
      addVendor(data)
      setVendor(false)
    }
    setValidationErrors(initialValues)
  }
  const isDisabled = () => {
    if (
      Object.values(validationErrors).some((item) => item) ||
      !Object.values(formData).every((item) => item) ||
      fetchLoading
    ) {
      return true
    }
    return false
  }
  let disabledFunc = isDisabled()

  return (
    <Modal
      onClose={() => {
        Data ? setShowEditModal(false) : setVendor(false)
      }}
      title={Data ? "Update Vendor details" : "Add New Vendor"}
    >
      <form autoComplete="off" className="modal-form">
        <FormField
          label="Manager Name"
          type="text"
          id="manager"
          name="managerName"
          value={formData.managerName}
          onChange={handleChange}
          validationError={validationErrors.managerName}
          className="modal-data"
          inputClassName="form-control"
        />
        <FormField
          label="Manager Email"
          type="email"
          id="managerEmail"
          name="managerEmail"
          value={formData.managerEmail}
          onChange={handleChange}
          validationError={validationErrors.managerEmail}
          className="modal-data"
          inputClassName="form-control"
        />
        <FormField
          label="Vendor"
          type="text"
          id="vendor"
          name="vendorName"
          value={formData.vendorName}
          onChange={handleChange}
          validationError={validationErrors.vendorName}
          className="modal-data"
          inputClassName="form-control"
        />
        <FormField
          label="Vendor Email"
          type="email"
          id="vendorEmail"
          name="vendorEmail"
          value={formData.vendorEmail}
          onChange={handleChange}
          validationError={validationErrors.vendorEmail}
          className="modal-data"
          inputClassName="form-control"
        />
        <FormField
          label="Department"
          type="text"
          id="department"
          name="department"
          value={formData.department}
          onChange={handleChange}
          validationError={validationErrors.department}
          className="modal-data"
          inputClassName="form-control"
        />
      </form>
      <div className="editBtn-wrpper">
        <CustomButton
          type="submit"
          className={disabledFunc ? "isDisable" : "edit-btn"}
          title={Data ? "Update" : "Save"}
          disabled={disabledFunc}
          onClick={handleSubmit}
        />
        {Data && (
          <CustomButton
            type="button"
            className="clear-btn"
            title="Clear"
            onClick={() => setFormData(initialValues)}
          />
        )}
        <CustomButton
          type="button"
          className="cancel-btn"
          title="Cancel"
          onClick={() => {
            Data ? setShowEditModal(false) : setVendor(false)
          }}
        />
      </div>
    </Modal>
  )
}

export default AddUpdateVendorData
