/* eslint-disable import/no-anonymous-default-export */
import { Layout, Menu } from "antd"
import CONFIG from "../../utils/constants"

const { Sider } = Layout

export default ({
  activeMenu,
  activeSubMenu,
  setCollapsed,
  collapsed,
  menuSelectHandler,
  selectedMenu
}) => {
  const { NAVBAR_MENU } = CONFIG

  const getSubMenuItems = () => {
    return NAVBAR_MENU.find(({ key }) => key === activeMenu).subMenu
  }

  const subMenu = getSubMenuItems()
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ position: "inherit" }}
    >
      {/* <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[activeSubMenu]}
        items={subMenu.map(({ key, label, icon }) => ({
          key,
          icon,
          label,
          onClick: () => menuSelectHandler(key, "subMenu"),
        }))}
      /> */}
      <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedMenu]}
          items={NAVBAR_MENU.map(({ key, label }) => ({
            key,
            label,
            onClick: () => menuSelectHandler(key, "menu"),
          }))}
        />
      
    </Sider>
  )
}
