import makeApiCall from "./BaseApi";

const { REACT_APP_BASE_URL } = process.env;

const getAllVendorsData = async () => {
  const url = `${REACT_APP_BASE_URL}vendorAndManager/user`;
  return await makeApiCall("get", url);
};

const updateVendorEntry = async (objectId, data) => {
  const url = `${REACT_APP_BASE_URL}vendorAndManager/users/objects/${objectId}`;
  return await makeApiCall("put", url, data);
};

const deleteVendor = async (objectId) => {
  const url = `${REACT_APP_BASE_URL}vendorAndManager/users/objects/${objectId}`;
  return await makeApiCall("delete", url);
};

const createVendor = async (data) => {
  const url = `${REACT_APP_BASE_URL}vendorAndManager/createUser`;
  return await makeApiCall("post", url, data, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
};

const getAllPendingInvoiceData = async () => {
  const url = `${REACT_APP_BASE_URL}allConvos/filterByEmail`;
  return await makeApiCall("get", url);
};

const getUserProfileData = async () => {
  const url = `${REACT_APP_BASE_URL}gmailApi/user_profile`;
  return await makeApiCall("get", url);
};

export {
  getAllVendorsData,
  updateVendorEntry,
  deleteVendor,
  createVendor,
  getAllPendingInvoiceData,
  getUserProfileData,
};
