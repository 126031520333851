import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import CustomTable from "../../utils/common/CustomTable";
import {
  getAllPendingInvoiceData,
  getUserProfileData,
} from "../../api/function";
import GoogleButton from "react-google-button";
import { useGoogleLogin } from "@react-oauth/google";
import CONFIG from "../../utils/constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { UserContext } from "../../App";

const GoogleLoginButton = ({ user, getAccessToken, setRefetchUserDetails }) => {
  const { GOOGLE_AUTH_SETTINGS, GOOGLE_SCOPE, GMAIL_SCOPE } = CONFIG;

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        let returnedData = await getAccessToken({
          ...tokenResponse,
          redirectURL: GOOGLE_AUTH_SETTINGS.redirect_uri,
        });
        secureLocalStorage.setItem("authUser", returnedData);
        setRefetchUserDetails(true);
      } catch (err) {
        toast.error(err?.response?.data?.error);
      }
    },
    ...GOOGLE_AUTH_SETTINGS,
    scope: GOOGLE_SCOPE + " " + GMAIL_SCOPE,
    isGmail: true,
    hint: user.email,
    onError: (errorResponse) => toast(errorResponse),
  });

  return (
    <div className="add-login">
      <GoogleButton onClick={googleLogin} />
    </div>
  );
};

const PendingInvoices = () => {
  const {
    API_URL: { BASE_URL, getLoginURL },
    GMAIL_SCOPE,
  } = CONFIG;

  const { user, setUser } = useContext(UserContext);

  const isGmailScopeAccess = user?.scope.includes(GMAIL_SCOPE.split(" ")[0]);

  const columns = [
    {
      key: "department",
      title: "Department",
      dataIndex: "department",
      align: "center",
    },
    {
      key: "manager",
      title: "Manager",
      dataIndex: "managerName",
      align: "center",
    },
    {
      key: "Vendor",
      title: "Vendor",
      dataIndex: "vendorName",
      align: "center",
    },
    {
      key: "mailSentAt",
      title: "Mail Sent At",
      dataIndex: "mailSentAt",
      align: "center",
      render: (text) => <span>{moment(text).format("DD/MM/YYYY")}</span>,
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      align: "center",
    },
    {
      key: "services",
      title: "Services",
      dataIndex: "services",
      align: "center",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text) => (
        <button
          className={
            text === "pay"
              ? "accepted-btn"
              : text === "reject"
              ? "rejected-btn"
              : "null-btn"
          }
        >
          {(function () {
            switch (text) {
              case "pay":
                return "Accepted";
              case "reject":
                return "Rejected";
              case "no reply":
                return "No Reply";
              case "pending":
                return "Pending";
              default:
                return "No Reply";
            }
          })()}
        </button>
      ),
    },
  ];
  //view modal
  const [dataArr, setDataArr] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [googleLoginContent, setGoogleLoginContent] = useState();
  const [refetchUserDetails, setRefetchUserDetails] = useState(false);

  console.log(dataArr, user, isGmailScopeAccess);

  const fetchLoggedInUserData = async () => {
    setFetchLoading(true);
    try {
      const {
        data: { profile },
      } = await getUserProfileData();
      setUser(profile);
      setFetchLoading(false);
      setRefetchUserDetails(false);
    } catch (err) {}
  };

  const fetchData = useCallback(async () => {
    setFetchLoading(true);
    try {
      const res = await getAllPendingInvoiceData();

      if (res.data) {
        setFetchLoading(false);
        setDataArr(res?.data?.threads ? res?.data?.threads : []);
      }
    } catch (err) {
      setFetchLoading(false);
      toast.error(err?.response?.data?.error);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    // Call the fetchData function once the component is mounted
    if (isMounted && isGmailScopeAccess) {
      fetchData();
    }

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [fetchData, isGmailScopeAccess, user]);

  useEffect(() => {
    if (user) {
      refetchUserDetails && fetchLoggedInUserData();
      setGoogleLoginContent({ ...googleLoginContent, hint: user?.email });
    } else if (!user) fetchLoggedInUserData();
  }, [user, refetchUserDetails]);

  const formatString = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const renderData = dataArr.map(
    ({
      department,
      managerName,
      vendorName,
      managerEmail,
      vendorEmail,
      amount,
      services,
      status,
    }) => {
      return {
        department,
        managerEmail,
        vendorEmail,
        amount,
        services,
        status,
        managerName: formatString(managerName),
        vendorName: formatString(vendorName),
      };
    }
  );

  const getAccessToken = async (tokenResponse) => {
    setFetchLoading(true);
    const result = await axios.post(getLoginURL(BASE_URL), { tokenResponse });
    setFetchLoading(false);
    const authHeader = result.headers.get("Authorization");
    const token = authHeader ? authHeader.split(" ")[1] : null;
    return token;
  };

  return (
    <>
      {user && !isGmailScopeAccess && (
        <GoogleLoginButton
          user={user}
          getAccessToken={getAccessToken}
          setRefetchUserDetails={setRefetchUserDetails}
        />
      )}
      <CustomTable
        columns={columns}
        dataSource={renderData}
        bordered
        fetchLoading={fetchLoading}
        rowSelection={false}
      />
    </>
  );
};
export default PendingInvoices;
