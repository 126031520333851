/* eslint-disable import/no-anonymous-default-export */
import React, { useContext } from "react";
import images from "../../assets/images";
import { Menu, Layout } from "antd";
import CONFIG from "../../utils/constants";
import secureLocalStorage from "react-secure-storage";
import NavigationHOC from "../../HOCs/Navigation";
import { UserContext } from "../../App";

const { POC } = images;
const { Header } = Layout;

export default NavigationHOC(
  ({ selectedMenu, menuSelectHandler, redirect }) => {
    const { NAVBAR_MENU } = CONFIG;

    const { setUser } = useContext(UserContext);

    return (
      <Header className="d-flex header">
        <div className="logo">
          <img src={POC} alt="LOGO" />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[selectedMenu]}
          items={NAVBAR_MENU.map(({ key, label }) => ({
            key,
            label,
            onClick: () => menuSelectHandler(key, "menu"),
          }))}
        />
        <div>
          <button
            className="logout-btn"
            onClick={() => {
              secureLocalStorage.clear("authUser");
              setUser(null);
              redirect("/");
            }}
          >
            Logout
          </button>
        </div>
      </Header>
    );
  }
);
