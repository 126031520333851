import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import "./assets/scss/main.scss";
import Routes from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createContext, useState } from "react";

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <BrowserRouter>
          <Routes />
          <ToastContainer />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </UserContext.Provider>
  );
}

export default App;
