import React, { useCallback, useEffect, useState } from "react";
import ChartGraph from "./areagraph/index";
import LineGraph from "./linegraph/index";
import PieGraph from "./piechart/index";
import { BsCloudDownload } from "react-icons/bs";
import BarGraph from "./bargraph";
import ComposeBarGraph from "./composebargraph";
import Task from "./tiles/index";
import { getAllPendingInvoiceData } from "../../api/function";
import { toast } from "react-toastify";
import ActiveVendors from "../vendors/ActiveVendors";

const Dashboard = () => {
  const [dataInvoice, setDataInvoice] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);

  // const fetchData = useCallback(async () => {
  //   setFetchLoading(true);
  //   try {
  //     const res = await getAllPendingInvoiceData();

  //     if (res.data) {
  //       setFetchLoading(false);
  //       setDataInvoice(res?.data?.threads ? res?.data?.threads : []);

  //     }
  //   } catch (err) {
  //     // Handle the error
  //     setFetchLoading(false);
  //     toast.error(err?.response?.data?.error);
  //   }
  // }, []);

  // useEffect(()=>{
  //   fetchData()
  // },[fetchData])
  return (
    // <div className="dashboard-main">
    //   <div className="container-fluid">
    //     <div className="dashboard-container">
    //       <h5 className="dashboard-title">DASHBOARD</h5>
    //       <button type="button" className="generate-btn">
    //         <BsCloudDownload />
    //         Generate Report
    //       </button>
    //     </div>
    //     <div className="charts">
    //       <Task />
    //     </div>
    //     <div className="row">
    //       <div className="col-md-7">
    //         <div className="graph-chart">
    //           <ChartGraph />
    //         </div>
    //       </div>
    //       <div className="col-md-5">
    //         <div className="pie-chart">
    //           <PieGraph />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="col-md-4">
    //         <div className="linegraph-container">
    //           <LineGraph />
    //         </div>
    //       </div>
    //       <div className="col-md-4">
    //         <div className="composedgraph-container">
    //           <BarGraph />
    //         </div>
    //       </div>
    //       <div className="col-md-4">
    //         <div className="composed-bargraph-container ">
    //           <ComposeBarGraph />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <ActiveVendors />
  );
};

export default Dashboard;
