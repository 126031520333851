import React from "react"
import { Breadcrumb } from "antd"
import CONFIG from "../../utils/constants"

const BreadcrumbRoute = ({ activeMenu, activeSubMenu }) => {
  const { BREAD_CRUMBS } = CONFIG
  let breadcrumb = `${BREAD_CRUMBS[activeMenu]}${
    activeSubMenu ? ` / ${BREAD_CRUMBS[activeSubMenu]}` : ""
  }`

  return (
    <div className="ps-4">
      <Breadcrumb
        className="breadcrumb"
        items={[JSON.parse(JSON.stringify({ title: breadcrumb }))]}
      />
    </div>
  )
}

export default BreadcrumbRoute
