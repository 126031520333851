import React from "react";
import { Table } from "antd";

const CustomTable = ({
  columns,
  dataSource,
  bordered,
  title,
  titleClassName,
  fetchLoading,
  rowSelection,
  refetchData,
}) => {
  const getRowClassName = (record) =>
    record?.Response === "Accepted"
      ? "green"
      : record?.Response === "Rejected"
      ? "red"
      : "yellow";

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowClassName={getRowClassName}
      bordered={bordered}
      title={() => <h2 className={titleClassName}>{title}</h2>}
      rowKey={(record) => record._id}
      rowSelection={rowSelection}
      loading={fetchLoading}
    />
  );
};

export default CustomTable;
