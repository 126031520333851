/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import CONFIG from "../../utils/constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useGoogleLogin } from "@react-oauth/google";
import NavigationHOC from "../../HOCs/Navigation";
import { toast } from "react-toastify";
// import GoogleIcon from "../../assets/images/google.png";
import GoogleButton from "react-google-button";

export default NavigationHOC(({ redirect }) => {
  const {
    API_URL: { BASE_URL, getLoginURL },
    GOOGLE_SCOPE,
    GOOGLE_AUTH_SETTINGS,
  } = CONFIG;

  const [loader, setLoader] = useState(false);

  const getAccessToken = async (tokenResponse) => {
    setLoader(true);
    const result = await axios.post(getLoginURL(BASE_URL), { tokenResponse });
    setLoader(false);
    const authHeader = result.headers.get("Authorization");
    const token = authHeader ? authHeader.split(" ")[1] : null;
    return token;
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        let returnedData = await getAccessToken({
          ...tokenResponse,
          redirectURL: GOOGLE_AUTH_SETTINGS.redirect_uri,
        });
        secureLocalStorage.setItem("authUser", returnedData);
        redirect("/vendorList");
      } catch (err) {
        toast.error(err?.response?.data?.error);
      }
    },
    ...GOOGLE_AUTH_SETTINGS,
    scope: GOOGLE_SCOPE,
    onError: (errorResponse) => toast(errorResponse),
  });

  return (
    <React.Fragment>
      <GoogleButton onClick={googleLogin} />

      {loader && (
        <div id="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
    </React.Fragment>
  );
});
