import React from "react"

const CustomButton = ({
  className,
  title,
  onClick,
  type,
  disabled,
  ...props
}) => {
  return (
    <button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {title}
    </button>
  )
}

export default CustomButton
