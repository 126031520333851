import React from "react"
import secureLocalStorage from "react-secure-storage"
import { IoIosArrowBack } from "react-icons/io"
import NavigationHOC from "../../HOCs/Navigation"

export default NavigationHOC(({ redirect }) => {
  const localData = secureLocalStorage.getItem("authUser")
  const dashboardcall = () => {
    if (localData) {
      redirect("/vendorList")
    } else {
      redirect("/")
    }
  }

  return (
    <div className="error-header">
      <h3>Page not found !</h3>

      <button
        className="logout-btn d-flex align-items-center"
        onClick={() => dashboardcall()}
      >
        <IoIosArrowBack className="me-1" />
        GO Back
      </button>
    </div>
  )
})
