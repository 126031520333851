import React from 'react'

const FormField = ({
  label,
  type,
  id,
  name,
  value,
  onChange,
  validationError,
  className,
  inputClassName
}) => {
  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={inputClassName}
      />
      {validationError && <span className="error">{validationError}</span>}
    </div>
  )
}

export default FormField